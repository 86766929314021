import * as React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import { Icon, Promotion, SEO, ServiceBlockGroup, Container } from '@components';

import * as s from '../styles/home.module.scss';

function IndexPage() {
    return (
        <>
            <SEO title="Home" />
            <div className={s.hero}>
                <StaticImage
                    src="../images/hero3.jpg"
                    alt="Beautiful kitchen remodel"
                    layout="fullWidth"
                    aspectRatio={3}
                    breakpoints={[750, 1080, 1366, 1920, 2560, 3840]}
                />
                <div className={s.heroTitle}>
                    <span>Specializing in Home Remodeling</span>
                    <h2>Quality you can count on</h2>
                </div>
            </div>
            <Container>
                <div className={s.promotion}>
                    <Promotion
                        link="https://apply.svcfin.com/home/dealerAuthentication?id=815126916&key=1742219857"
                        linkName="Apply Now"
                        icon={<Icon name="finance" />}
                    >
                        Let your dream become reality. Enjoy deferred or no interest on your next project with a
                        qualified loan.
                    </Promotion>
                </div>
                <ServiceBlockGroup featured />
                <div className={s.seeAll}>
                    <Link to="/services">See all our services</Link>
                </div>
                <div className={s.about}>
                    <div>
                        <h2 className={s.aboutTitle}>
                            <strong>Peace of Mind</strong> for your next project
                        </h2>
                        <p className={s.aboutText}>
                            Whether it&apos;s getting your home remodeled or fixing damage that&apos;s been done to your
                            home, we&apos;re here to help you every step of the way.
                        </p>
                        <p className={s.aboutText}>
                            At Peace of Mind Contracting, we&apos;ve built our livelihoods on remodeling people&apos;s
                            homes and businesses. From the simple to the lavish, we&apos;ve experienced it all and we
                            can help you every step of the way. No job is too small or too big for our knowledgeable
                            crews.
                        </p>
                    </div>
                    <div>
                        <StaticImage
                            src="../images/hero.jpg"
                            alt="Beautiful kitchen remodel"
                            layout="constrained"
                            aspectRatio={2}
                        />
                    </div>
                </div>
            </Container>
        </>
    );
}

export default IndexPage;
